import Vue from 'vue'
import { ONL_AUTH_FIELDS } from "@/store/mutation-types"
import { getAction } from '@/api/manage'


const keepPage = {
  state: {
    //页面分页参数
    currentPageTodo:undefined,
    currentPageDone:undefined,
    currentPageUnread:undefined,
    currentPageRead:undefined,
    currentPageStart:undefined,
    //当前tab页key
    tabKey:undefined,
  },
  mutations: {
    setCurrentPageTodo: (state, val) => {
      console.log('currentPageTodo',val)
      Vue.set(state, 'currentPageTodo', val)
    },
    setCurrentPageDone: (state, val) => {
      console.log('currentPageDone',val)
      Vue.set(state, 'currentPageDone', val)
    },
    setCurrentPageUnread: (state, val) => {
      console.log('currentPageUnread',val)
      Vue.set(state, 'currentPageUnread', val)
    },
    setCurrentPageRead: (state, val) => {
      console.log('currentPageRead',val)
      Vue.set(state, 'currentPageRead', val)
    },
    setCurrentPageStart: (state, val) => {
      console.log('currentPageStart',val)
      Vue.set(state, 'currentPageStart', val)
    },
    setTabKey: (state, val) => {
      console.log('tabKey',val)
      Vue.set(state, 'tabKey', val)
    }
  },
  actions: {
  },
  getters:{
    getCurrentPageTodo: state => state.currentPageTodo,
    getCurrentPageDone: state => state.currentPageDone,
    getCurrentPageUnread: state => state.currentPageUnread,
    getCurrentPageRead: state => state.currentPageRead,
    getCurrentPageStart: state => state.currentPageStart,
    getTabKey: state => state.tabKey
  }
}

export default keepPage