<template>
  <div class="footer">
<!--    <div class="links">
      <a href="http://www.jeecg.com" target="_blank">JEECG 首页</a>
      <a href="https://github.com/zhangdaiscott/jeecg-boot" target="_blank">
        <a-icon type="github"/>
      </a>
      <a href="https://ant.design/">Ant Design</a>
      <a href="https://vuecomponent.github.io/ant-design-vue/docs/vue/introduce-cn/">Vue Antd</a>
    </div>
    <div class="copyright">
      Copyright
      <a-icon type="copyright"/>
      2019 <span>JEECG开源社区 出品</span>
    </div>-->
    <div class="copyright">
      (C)COPYRIGHT 2020, GOLDENIT TEK ALL RIGHTS RESERVED. 版权所有 （C）安徽中科国金智能科技有限公司 皖ICP备05015028号
    </div>
  </div>
</template>

<script>
  export default {
    name: "LayoutFooter"
  }
</script>

<style lang="less" scoped>
  .footer {
    padding: 0 16px;
    margin: 24px 0 24px;
    text-align: center;

    .links {
      margin-bottom: 8px;

      a {
        color: rgba(0, 0, 0, .45);

        &:hover {
          color: rgba(0, 0, 0, .65);
        }

        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
    .copyright {
      //color: rgba(0, 0, 0, .45);
      color: #a7a7a7;
      font-size: 14px;
    }
  }
</style>
