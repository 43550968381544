import Vue from "vue";
import * as dayjs from "dayjs";
import {getDictItemsFromCache} from '@/api/api'

Vue.filter('NumberFormat', function (value) {
    if (!value) {
        return '0'
    }
    let intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
    return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(dataStr).format(pattern)
})

/** 字符串超长截取省略号显示 */
Vue.filter('ellipsis', function (value, vlength = 25) {
    if (!value) {
        return "";
    }
    if (value.length > vlength) {
        return value.slice(0, vlength) + '...'
    }
    return value
})

/** 获取字典值集合 */
Vue.filter('dict', function (value) {
    if (!value) {
        return [];
    }
    const dictData = getDictItemsFromCache(value)
    return dictData.map((i) => {
        return {key: i.value, label: i.text};
    })
})

/** 翻译字典值 */
Vue.filter('dictValue', function (dict, value) {
    if (!value) {
        return '';
    }
    const dictData = getDictItemsFromCache(dict)
    const items = dictData.filter((o) => value === o.value);
    return (items.length > 0) ? items[0].text : ""
})
