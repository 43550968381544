import Vue from 'vue'
import {login, mLogin, logout, phoneLogin, thirdLogin} from "@/api/login"
import {
    ACCESS_TOKEN,
    USER_NAME,
    USER_INFO,
    USER_AUTH,
    USER_ROLES,
    USER_DEPARTS,
    SYS_BUTTON_AUTH,
    UI_CACHE_DB_DICT_DATA,
    UI_CACHE_DB_WORKDAY_DATA,
    TENANT_ID,
    CACHE_INCLUDED_ROUTES
} from "@/store/mutation-types"
import {welcome} from "@/utils/util"
import {queryPermissionsByUser, queryRolesByUser} from '@/api/api'
import {getAction} from '@/api/manage'

const cacheData = function () {
    getAction("api/cache/workday").then((res) => {
        if (res.success) {
            Vue.ls.remove(UI_CACHE_DB_WORKDAY_DATA)
            Vue.ls.set(UI_CACHE_DB_WORKDAY_DATA, res.result, 7 * 24 * 60 * 60 * 1000)
        }
    })
}

const commitUser = function (commit, data) {
    const userInfo = data.userInfo
    const departs = data.departs
    Vue.ls.set(ACCESS_TOKEN, data.token, 7 * 24 * 60 * 60 * 1000)
    Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
    Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
    Vue.ls.set(USER_DEPARTS, departs, 7 * 24 * 60 * 60 * 1000)
    Vue.ls.set(UI_CACHE_DB_DICT_DATA, data.sysAllDictItems, 7 * 24 * 60 * 60 * 1000)
    commit('SET_TOKEN', data.token)
    commit('SET_INFO', userInfo)
    commit('SET_DEPARTS', departs)
    commit('SET_NAME', {
        username: userInfo.username,
        realname: userInfo.realname,
        welcome: welcome()
    })
    commit('SET_AVATAR', userInfo.avatar)
}

const user = {
    state: {
        token: '',
        username: '',
        realname: '',
        tenantid: '',
        welcome: '',
        avatar: '',
        permissionList: [],
        info: {},
        departs: [],
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, {username, realname, welcome}) => {
            state.username = username
            state.realname = realname
            state.welcome = welcome
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_PERMISSIONLIST: (state, permissionList) => {
            state.permissionList = permissionList
        },
        SET_INFO: (state, info) => {
            state.info = info
        },
        SET_DEPARTS: (state, departs) => {
            state.departs = departs
        },
        SET_TENANT: (state, id) => {
            state.tenantid = id
        },
    },

    actions: {
        // CAS验证登录
        ValidateLogin({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                getAction("/sys/cas/client/validateLogin", userInfo).then(response => {
                    console.log("----cas 登录--------", response);
                    if (response.success) {
                        const result = response.result
                        commitUser(commit, result)
                        cacheData()
                        resolve(response)
                    } else {
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登录
        Login({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    if (response.code == '200') {
                        const result = response.result
                        commitUser(commit, result)
                        cacheData()
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        //手机号登录
        PhoneLogin({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                phoneLogin(userInfo).then(response => {
                    if (response.code == '200') {
                        const result = response.result
                        commitUser(commit, result)
                        cacheData()
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 手机登录
        MLogin({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                mLogin(userInfo).then(response => {
                    if (response.code == '200') {
                        const result = response.result
                        commitUser(commit, result)
                        cacheData()
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取用户信息
        GetPermissionList({commit}) {
            return new Promise((resolve, reject) => {
                queryPermissionsByUser().then(response => {
                    const menuData = response.result.menu;
                    const authData = response.result.auth;
                    const allAuthData = response.result.allAuth;
                    const roles = response.result.roles;
                    //Vue.ls.set(USER_AUTH,authData);
                    sessionStorage.setItem(USER_AUTH, JSON.stringify(authData));
                    sessionStorage.setItem(SYS_BUTTON_AUTH, JSON.stringify(allAuthData));
                    sessionStorage.setItem(USER_ROLES, JSON.stringify(roles));
                    if (menuData && menuData.length > 0) {
                        //update--begin--autor:qinfeng-----date:20200109------for：JEECG-63 一级菜单的子菜单全部是隐藏路由，则一级菜单不显示------
                        menuData.forEach((item, index) => {
                            if (item["children"]) {
                                let hasChildrenMenu = item["children"].filter((i) => {
                                    return !i.hidden || i.hidden == false
                                })
                                if (hasChildrenMenu == null || hasChildrenMenu.length == 0) {
                                    item["hidden"] = true
                                }
                            }
                        })
                        //console.log(" menu show json ", menuData)
                        //update--end--autor:qinfeng-----date:20200109------for：JEECG-63 一级菜单的子菜单全部是隐藏路由，则一级菜单不显示------
                        commit('SET_PERMISSIONLIST', menuData)
                    } else {
                        reject('getPermissionList: permissions must be a non-null array !')
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 登出
        Logout({commit, state}) {
            return new Promise((resolve) => {
                let logoutToken = state.token;
                commit('SET_TOKEN', '')
                commit('SET_PERMISSIONLIST', [])
                Vue.ls.remove(ACCESS_TOKEN)
                Vue.ls.remove(UI_CACHE_DB_DICT_DATA)
                Vue.ls.remove(CACHE_INCLUDED_ROUTES)
                //console.log('logoutToken: '+ logoutToken)
                logout(logoutToken).then(() => {
                    if (process.env.VUE_APP_SSO == 'true') {
                        let sevice = 'http://' + window.location.host + '/'
                        let serviceUrl = encodeURIComponent(sevice)
                        window.location.href = process.env.VUE_APP_CAS_BASE_URL + '/logout?service=' + serviceUrl
                    }
                    resolve()
                }).catch(() => {
                    resolve()
                })
            })
        },
        // 第三方登录
        ThirdLogin({commit}, param) {
            return new Promise((resolve, reject) => {
                thirdLogin(param.token, param.thirdType).then(response => {
                    if (response.code == '200') {
                        const result = response.result
                        commitUser(commit, result)
                        cacheData()
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        saveTenant({commit}, id) {
            Vue.ls.set(TENANT_ID, id, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TENANT', id)
        },

    }
}

export default user
