import store from '@/store'
import {axios} from '@/utils/request'
import {notification} from 'ant-design-vue'
import Qs from "qs"

export const ajax = function (config, key) {
    return new Promise((resolve, reject) => {
        store.dispatch('loading', key).then(() => {
            axios(config).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            }).finally(()=>store.commit('CLOSE_LOADING', key))
        })
    })
}

export const get = function (url, params, key) {
    const config = {
        url: url,
        method: 'get',
        params: params,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }
    return ajax(config, key)
}

export const post = function (url, data, key) {
    const config = {
        url: url,
        method: 'post',
        data: data
    }
    return ajax(config, key)
}

const loading = {
    ajax,
    get,
    post,
};


const install = function (Vue) {
    if (this.installed) {
        return;
    }
    this.installed = true;

    if (!axios) {
        console.error('You have to install axios');
        return;
    }
    Vue.prototype.$loading = loading;
};

export default {
    install
}
