const loading = {
    state: {
        loading: {def: false},
    },
    mutations: {
        SET_LOADING: (state, key) => {
            if (key) {
                state.loading[key] = true
            } else {
                state.loading.def = true
            }
        },
        CLOSE_LOADING: (state, key) => {
            if (key) {
                state.loading[key] = false
            } else {
                state.loading.def = false
            }
        },
        RESET_LOADING: (state) => {
            state.loading = {def: false}
        },
    },
    actions: {
        loading: ({commit}, key) => {
            commit('SET_LOADING', key)
        },
    },
    getters: {
        loading: (state) => (key) => {
            return key ? state.loading[key] : state.loading.def
        }
    }
}

export default loading
